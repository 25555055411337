<template>
    <section class="pt-3 overflow-auto custom-scroll" style="height:calc(100vh - 70px)">
        <div class="row mx-0 align-items-center mb-5">
            <i class="icon-back f-20 text-black cr-pointer" @click="$router.back()" />
            <div class="col-auto text-black f-600 f-18">
                {{ editarMode ? 'Editar' : 'Crear' }} usuario
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-auto text-center px-0">
                <slim-cropper ref="cropImagen" :options="slimOptions" class="border br-4 bg-white" style="height:120px;width:120px;background:#F8F9FF;">
                    <div slot="label" class="">
                        <img src="/img/modales/camera.svg" alt="" />
                    </div>
                </slim-cropper>
                <small class="text-black">
                    Foto de perfil
                </small>
            </div>
            <ValidationObserver ref="validacion" tag="div" class="col-xl-4 col-lg-4 col-md-5 col-sm-5 col-5">
                <div class="row mx-0">
                    <div class="col-12">
                        <label class="text-black f-12 pl-3">
                            Nombre del Usuario
                        </label>
                        <ValidationProvider v-slot="{errors}" rules="required|max:30" name="nombre usuario">
                            <el-input v-model="model.nombre" maxlength="30" size="small" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="text-black f-12 pl-3">
                            Correo Electronico
                        </label>
                        <ValidationProvider v-slot="{ errors, failedRules }" rules="required|email" name="correo electrónico">
                            <el-input v-model="model.correo" size="small" type="email">
                                <i v-if="failedRules.email" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                <i v-else slot="suffix" class="icon-ok-circled-outline text-success el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="text-black pl-3 f-12">
                            Contraseña
                        </label>
                        <ValidationProvider v-slot="{ errors }" vid="password" :rules="reglas.contrasena" name="Contraseña nueva">
                            <el-input v-model="model.password" size="small" :type="ver_pass?'text':'password'" :placeholder="editPassword ? '**********' : 'Contraseña'">
                                <i v-if="!ver_pass" slot="suffix" class="el-input__icon icon-eye text-muted cr-pointer f-14" @click="ver_pass=true" />
                                <i v-else slot="suffix" class="el-input__icon icon-eye-off text-muted cr-pointer f-17" @click="ver_pass=false" />
                            </el-input>
                            <span class="text-danger f-11"> {{ errors[0] }} </span>
                            <p class="text-muted f-12 mt-2">La contraseña debe tener mínimo 8 cacacteres y contener al menos 1 letra</p>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 mt-3">
                        <label class="text-black f-12 pl-3">
                            Confirmar Contraseña
                        </label>
                        <ValidationProvider v-slot="{ failedRules, errors }" rules="required_if:password|confirmed:password" name="confirmar contraseña">
                            <el-input v-model="model.pass_confirmar" size="small" type="password" :placeholder="editPassword ? '**********' : 'Confirmar contraseña'">
                                <i v-if="failedRules.confirmed && model.password !== ''" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                <i v-else slot="suffix" class="icon-ok-circled-outline text-success el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-12 d-middle justify-content-star my-3">
                        <el-checkbox v-model="model.superadmin" class="ml-4 check-green">
                            Super Administrador
                        </el-checkbox>
                        <el-tooltip class="mx-2" placement="bottom" effect="light" content="Puedo Eliminar cualquier usuario excepto asi mismo">
                            <i class="icon-info-circled-alt f-20 text-general" />    
                        </el-tooltip>
                    </div>
                    <div class="col-8">
                        <button type="button" class="btn-general-small w-100 px-3" @click="crear_usuario">
                            {{ editarMode ? 'Editar' : 'Crear' }} Usuario
                        </button>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </section>
</template>
<script>
import Usuarios from "~/services/usuarios/index"

export default {
    data(){
        return{
            ver_pass: false,
            editarMode: false,
            model: {
                id: null,
                nombre: '',
                correo: '',
                password: '',
                imagen: '',
                pass_confirmar: '',
                superadmin: false
            },
            reglas: {
                contrasena: {
                    required: this.requiredPassword(),
                    min: 8,
                    regex: /^(?=.*\d)(?=.*[a-zA-Z]?)[a-zA-Z0-9]{8,200}$/
                }
            },
            slimOptions: {
                ratio: '1:1',
                label: 'Subir',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            editPassword: false,
        }
    },
    computed:{
        idUser(){
            return Number(this.$route.params.idUser);
        }
    },
    mounted(){
        if(this.idUser){
            this.editarMode = true;
            this.getData();
        }
    },
    methods: {
        async getData(){
            try {
                const {data} = await Usuarios.getDataUser(this.idUser);
                this.model.id = data.user.id;
                this.model.nombre = data.user.nombre;
                this.model.correo = data.user.correo;
                this.model.imagen = data.user.imagen;
                this.model.superadmin = parseInt(data.user.tipo) === 3 ? true : false;

                if(!_.isNull(data.user.password)){
                    this.editPassword = true;
                }

                if (!_.isNull(data.user.imagen)){
                    this.$refs.cropImagen.set_image(data.user.imagen_firmada)
                } else {
                    this.$refs.cropImagen.instanciaCrop.remove()
                }
            }catch(e){
                this.error_catch(e)
            }
        },
       
        async crear_usuario(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(valid){
                    const archivo = this.$refs.cropImagen.instanciaCrop.dataBase64.output
                    if(!archivo){
                        this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                        return false
                    }
                    this.model.imagen = archivo.image
                    const {data} = await Usuarios.crear_usuario(this.model)
                    this.notificacion('Mensaje', data.mensaje, 'success')
                    this.clear();
                    this.$router.back();
                }else{
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                }
            } catch (e){
                this.error_catch(e)
            }
        },

        requiredPassword(){
            if(this.$route.params.idUser){
                return false;
            }
            return true;
        },

        clear(){
            this.editPassword = false;
            this.ver_pass = false;
            this.editarMode = false;
            this.model = {
                nombre: '',
                correo: '',
                password: '',
                imagen: '',
                pass_confirmar: '',
                superadmin: false
            }
            this.$refs.validacion.reset()
            this.$refs.cropImagen.instanciaCrop.remove()
        },
    }
}
</script>