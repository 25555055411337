<template>
    <section class="pt-3">
        <div style="height:calc(100vh - 88px)">
            <div class="row mx-0 align-items-center mb-3">
                <!-- BuscarUusario -->
                <div class="col-8 text-black f-600 f-18">
                    Usuarios de manager
                </div>
                <div class="col-auto px-0">
                    <button type="button" class="btn-general-small px-3" @click="goNewUser()">
                        Crear Usuario
                    </button>
                </div>
            </div>
            <div class="row mx-0 px-2">
                <div v-for="(data, d) in usuarios.filter(c => !buscarUsuario
                    || c.nombre.toLowerCase().includes(buscarUsuario.toLowerCase()))" :key="d" class="card-user-manager mr-3 mb-3 bg-white"
                >
                    <div class="row mx-0 py-1">
                        <div class="br-12 mx-2" :class="data.tipo == 3 ? 'bg-orange' : 'bg-grey'" style="width:8px;height:66px;" />
                        <div class="col px-0">
                            <div class="row align-items-center h-100 mx-0">
                                <img :src="data.imagen_firmada" class="br-50 obj-cover" style="width:50px;height:50px;border:1px solid #DBDBDB" alt="" />
                                <div class="col h-100 px-0">
                                    <div class="row mx-0 my-1">
                                        <div class="col-10">
                                            <p class="f-15 text-muted2 f-600">
                                                {{ data.nombre }}
                                            </p>
                                        </div> 
                                        <el-tooltip v-if="data.tipo == 3" placement="bottom" effect="light" content="Super Admin">
                                            <div class="col-1 px-0">
                                                <i class="icon-star" style="color:#FF9D32;" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                    <div class="row mx-0 my-2">
                                        <div class="col-10">
                                            <p class="text-muted2 f-15 tres-puntos ">
                                                {{ data.correo }}
                                            </p>
                                        </div>
                                        <el-tooltip placement="bottom" effect="light">
                                            <div slot="content">
                                                <div class="p-2 d-middle-center cr-pointer" @click="goEdit(data.id)">
                                                    Editar
                                                </div>
                                                <div class="p-2 d-middle-center cr-pointer" @click="AbrirEliminarUsuario(data.id)">
                                                    Eliminar
                                                </div>
                                            </div>
                                            <div class="col-1 px-0 cr-pointer">
                                                <i class="icon-vertical-dots text-black f-20" />
                                            </div>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="usuarios.length == 0" class="row mx-0 justify-center">
                <div class="br-12 border d-middle-center position-relative" style="width:162px;height:162px;background-color:#F8F9FF;">
                    <i class="icon-clientes f-60" />
                    <p class="position-absolute text-muted2 f-15" style="left:-20px;top:170px;width:250px;">
                        Aun no se han creado usuarios
                    </p>
                </div>
            </div>
        </div>
        <!-- Modales -->
        <modalCrearUsuario ref="CrearUsuario" @actualizar="get_usuarios" />
        <modalEliminar ref="EliminarUsuario" titulo="Eliminar usuario" mensaje="¿Desea eliminar este usuario?" @eliminar="eliminar_usuario" />

        <!-- Modales -->
    </section>
</template>
<script>
import Usuarios from "~/services/usuarios/index";

export default {
    components: {
        modalCrearUsuario: () => import('./partials/modalCrearUsuario'),
    },
    data(){
        return{
            buscarUsuario: '',
            usuarios: []
        }
    },
    mounted(){
        this.get_usuarios()
    },
    methods: {
        async get_usuarios(){
            try {
                const { data } =  await Usuarios.get_usuarios()
                this.usuarios = data.usuarios
            } catch (error){
                this.error_catch(error)
            }
        },
        AbrirEliminarUsuario(id){
            this.id = id
            this.$refs.EliminarUsuario.toggle();
        },
        async eliminar_usuario(){
            try {
                const { data } =  await Usuarios.eliminar_usuario(this.id)
                this.get_usuarios()
                this.notificacion('Mensaje', data.mensaje, 'success')

            } catch (error){
                this.error_catch(error)
            }
        },
        goEdit(idUser){
            this.$router.push({name: 'manager.users.editar', params: { idUser: idUser }});
        },
        goNewUser(){
            this.$router.push({name: 'manager.users.crear'});
        },
    }
}
</script>
<style lang="scss">

.card-user-manager{
    height: 78px;
    width:401px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px;
}


.tooltip-item{
    color:#707070;
    border-radius: 4px;
}


.input-edit{
    .el-input{
        .el-input__inner{
            border-radius: 20px;
            text-align: center;
        }
    }
}
</style>