import axios from "axios"

const API = "manager-tiendas/usuarios";

const usuario = {
    get_usuarios(){
        return axios(`${API}/get-usuarios`)
    },
    crear_usuario(params){
        return axios.post(`${API}/crear-usuario`, params)
    },
    super_usuario(id){
        return axios.put(`${API}/${id}/super-usuario`)
    },
    eliminar_usuario(id_eliminar){
        return axios.delete(`${API}/${id_eliminar}/eliminar-usuario`)
    },
    /**
     * Endpoints nuevos
     */
    getDataUser(idUser){
        return axios.get(`${API}/${idUser}/user`);
    }
}

export default usuario
